var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "list" }, [
    _c("div", { staticClass: "tips" }, [_vm._v(" 选择需要导出的字段 ")]),
    _c(
      "div",
      [
        _c(
          "div",
          [
            _c(
              "a-checkbox",
              {
                attrs: {
                  indeterminate: _vm.indeterminate,
                  checked: _vm.checkAll,
                },
                on: { change: _vm.onCheckAllChange },
              },
              [_vm._v(" 基本信息 ")]
            ),
          ],
          1
        ),
        _c("br"),
        _c("a-checkbox-group", {
          attrs: { options: _vm.plainOptions },
          on: { change: _vm.onChange },
          model: {
            value: _vm.checkedList,
            callback: function ($$v) {
              _vm.checkedList = $$v
            },
            expression: "checkedList",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }